window.addEventListener("DOMContentLoaded", function () {
  $("#sec02_imgA").each(function () {
    var imgPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > imgPos - windowHeight + windowHeight / 5) {
      $(this).addClass("img-wrap");
    } else {
      $(this).removeClass("img-wrap");
    }
  });
});
